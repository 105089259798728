import { useEffect } from "react";
import OpenContentList from "../OpenContentList"

export default function POSKorona() {
    useEffect(() =>{
        document.title = "Korona | POS Solutions";
    }, []);
    const contentArray = 
    [
        {
            id: 1,
            title: "General Features",
            content: "",
            list: [
                {
                    subtitle: "Printable, Detailed Cashier Reports",
                    subcontent: ""
                },
                {
                    subtitle: "Customizable eCommerce Platform",
                    subcontent: ""
                },
                {
                    subtitle: "Accounting Software Integration",
                    subcontent: ""
                },
                {
                    subtitle: "Coupons, Bundled Deals, and Promotions",
                    subcontent: ""
                },
                {
                    subtitle: "Time Tracking and Payroll Features",
                    subcontent: ""
                },
                {
                    subtitle: "Multi-Location & Franchise Scalability",
                    subcontent: ""
                },
                {
                    subtitle: "Liquor Store Customer Loyalty Integration",
                    subcontent: ""
                },
                {
                    subtitle: "Age Verification Reminders",
                    subcontent: ""
                },
                {
                    subtitle: "Cloud-Based Liquor POS System",
                    subcontent: ""
                },
                {
                    subtitle: "Automatic Ordering for Inventory",
                    subcontent: ""
                },
                {
                    subtitle: "Employee Management & POS Permissions",
                    subcontent: ""
                },
                {
                    subtitle: "Case Breaks and Mix & Match Features",
                    subcontent: ""
                },
                {
                    subtitle: "Inventory Tracking with Handheld Devices",
                    subcontent: ""
                }
            ],
            image: "img/liquor-store2.jpg"
        },
        {
            id: 2,
            title: "POS System Software",
            content: "",
            list: [
                {
                    subtitle: "Cloud-Based Software:",
                    subcontent: "All data is secure and accessible in a remote cloud-based server, making it safe and convenient."
                },
                {
                    subtitle: "Promotions and Discounts:",
                    subcontent: "Change pricing and add specific deals with ease. All changes will be reflected in inventory and sales reports."
                },
                {
                    subtitle: "Intuitive Design:",
                    subcontent: "Staff training will be a breeze with KORONA POS remarkable ease of use. Don’t waste time with stressful training."
                },
                {
                    subtitle: "Accounting Integration:",
                    subcontent: "Integrate your POS software with Quickbooks, streamlining more of your business operations."
                },
                {
                    subtitle: "Refunds and Returns:",
                    subcontent: "Easily process all returns and refunds, whether it was cash or card. You can offer money back or store credit."
                },
                {
                    subtitle: "Employee Management:",
                    subcontent: "Set specific access controls for each employee and track transactional history with shift reports."
                },
                {
                    subtitle: "Time Clock:",
                    subcontent: "Make payroll a breeze by keeping all time clock management in your POS. Employees just log in and out through the system."
                },
                {
                    subtitle: "Receipt Deals:",
                    subcontent: "Add customizable promotions and discounts on each receipt to encourage customers to come back often."
                }
            ],
            image: "img/korona-pos-system-screen.jpg"
        },
        {
            id: 3,
            title: "Inventory Management",
            content: "",
            list: [
                {
                    subtitle: "Stock Notifications:",
                    subcontent: "Get updates for low stocks, over stocks, and out-of stocks directly from your POS."
                },
                {
                    subtitle: "Data Import:",
                    subcontent: "Keep everything under one umbrella. Import inventory databases quickly and easily for new locations or franchises."
                },
                {
                    subtitle: "Consolidated Inventory:",
                    subcontent: "Keep your inventory consolidated between various verticals in one space."
                },
                {
                    subtitle: "Ease of Receiving:",
                    subcontent: "Scan new deliveries into your inventory for fast and accurate counting."
                },
                {
                    subtitle: "Product Analysis:",
                    subcontent: "Get a report card on each item in your product catalog with a click of a button."
                },
                {
                    subtitle: "Labeling and Pricing:",
                    subcontent: "Print labels and price tags on products straight from your inventory management system."
                },
                {
                    subtitle: "Auto Ordering:",
                    subcontent: "Set automated order placements based on product par levels. Avoid stockouts entirely."
                },
                {
                    subtitle: "Track Shipping:",
                    subcontent: "Vendor management keep you updated on when items shipped and what’s in the shipment."
                },
                {
                    subtitle: "Mobile Inventory:",
                    subcontent: "Use KORONA POS inventory app for added convenience and flexibility."
                },
                {
                    subtitle: "Loss Prevention:",
                    subcontent: "Use KORONA POS inventory app for added convenience and flexibility."
                }
            ],
            image: "img/korona-inventory-screen.jpg"
        },
        {
            id: 4,
            title: "Reporting and Metrics",
            content: "",
            list: [
                {
                    subtitle: "Profits and Revenue:",
                    subcontent: "Easily track your total revenue versus total profits. Group reports by individual products or storewide."
                },
                {
                    subtitle: "Sell-Through Rate:",
                    subcontent: "Get insight into how your inventory moves seasonally with sell-through rate reports."
                },
                {
                    subtitle: "Time-Based Comparisons:",
                    subcontent: "Compare sales between weeks, months or years to see how you’ve grown and where you can improve."
                },
                {
                    subtitle: "Average Transactions:",
                    subcontent: "Learn what your average transaction amounts are, by the day or by the employee."
                },
                {
                    subtitle: "Conversion Rates:",
                    subcontent: "See how often an interested customer actually completes a purchase to get insight into your marketing and sales."
                },
                {
                    subtitle: "Turnover Rates:",
                    subcontent: "Learn how quickly certain products sell so that you can optimize your ordering."
                },
                {
                    subtitle: "Return Rates:",
                    subcontent: "Identify problematic products by generating return rate reports."
                },
                {
                    subtitle: "GMROI Reports:",
                    subcontent: "Optimize prices by closely following your gross margin return on investment for every item in your catalog."
                }
            ],
            image: "img/korona-report-screen.jpg"
        },
        {
            id: 5,
            title: "Retail Loss Prevention",
            content: "Don’t let cashiers void an entire sale after the transaction is completed. This is a common way to steal cash.",
            list: [
                {
                    subtitle: "Receipt Cancellation:",
                    subcontent: "Some cashiers will erase items on a ticket if the customer pays cash. KORONA POS doesn’t allow this."
                },
                {
                    subtitle: "Drawer Closing:",
                    subcontent: "Prevent further POS actions with an open drawer. Require employees to close the cash drawer after each sale."
                },
                {
                    subtitle: "Line Item Voids:",
                    subcontent: "Set your own customer settings with who can even one item on a ticket. It’s easy to require authentication."
                },
                {
                    subtitle: "Blind Cash Drops:",
                    subcontent: "Stop employees from seeing what their tendered cash drop is before they count their drawer. This helps identify recurring discrepancies."
                },
                {
                    subtitle: "Fully Customizable Features:",
                    subcontent: "All security features are fully customizable, letting KORONA POS work well for your store rather than requiring that you adapt to KORONA POS."
                }
            ],
            image: "img/security.jpg"
        }
    ]

    return(
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <br></br>
            <h3 className="colored-text ps-4 ms-3 pb-2">LIQUOR STORE</h3>
            <div className="row col-12 ps-5 ms-4 pb-0">
                <p className="col-md-2 col-sm-5"><a href="/POS-RelianceLiquor" className="text-decoration-none text-dark"><u>Reliance Liquor</u></a></p>
                <p className="col-md-2 col-sm-5"><a href="#POS-Korona" className="text-decoration-none colored-text"><strong>Korona POS</strong></a></p>
            </div>
            {/* <br></br> */}
            <div>
                <div className="row center-header orange-bg header">
                    <div className="header-vertical-align col-lg-7 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>Korona POS (Cloud Based)</strong></h1>
                                <div className="white-text ms-3 col-10">
                                    <h4>Design to handle liquor store operations.</h4>
                                    <p className="ms-2">No more worrying about outdated technology. All liquor store POS software updates are automatically installed in each of your POS terminals. This will continue, at no cost, for the duration of your subscription. You only get the best and stay up to date with KORONA POS.</p>
                                </div>
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 light-orange-bg tertiary-button orange-button-hover"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </span>
                    </div>
                    <img src="img/korona-header.png" alt="liquor" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                </div>
                {/* <div className="container mx-auto col-8">
                    <img src="img/korona-pos-screen.png" alt="Korona POS Screen" className="col-12 mx-auto"/>
                </div> */}

                <br></br>
                <div className="container mx-auto pt-4">
                    <h3 className="row mx-auto col-md-9 col-lg-8 col-xl-7 col-xxl-6 text-center dark-orange-text">Versatile, All-in-One Software Solutions for Retail</h3>
                    <h5 className="row mx-auto col-10 text-center lead">Korona POS represents many of the leading POS suppliers to find the right software solution for your small, medium, or large retail business.</h5>
                </div>
                <br></br>
                <br></br>
                <div className="mx-auto col-12 ps-4 pe-5">
                    <h2 className="mt-4"><em className="dark-orange-text">Features</em></h2>
                    <div className="ps-4 pe-4 pt-3">
                        <OpenContentList contentList={contentArray} color="orange" line="100%" isOpen={0}/>
                    </div>
                </div>
                <br></br>
                <br></br>
            </div>
            <br></br>
        </div>
    )
}