import { useEffect } from "react";

export default function ContactUs() {
    useEffect(() =>{
        document.title = "Contact Us";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            
            <div className="row col-12 center-header colored-bg header">
                <div className="header-vertical-align col-xxl-5 col-lg-6 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Contact Us</strong></h1> 
                        </div>
                    </span>
                </div>
                <div className="col-lg-6 col-xxl-7 pe-0">
                    <img src="img/contact-us-img.png" alt="" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                </div>
            </div>
            <div className="container">
                <br></br>
                <div className="pt-4 pb-5 mt-4 mx-auto">
                    <div className="row pb-3 ps-3">
                        <div className="col-md-6 col-12 ps-md-4">
                            <h5 className="colored-text pb-3 display-6">
                                <strong>Please feel free to contact us with any questions.</strong>
                            </h5>
                            <br></br>
                        </div>
                        <div className="col-md-6 col-12 mx-auto ps-lg-5">
                            <div className="col-lg-10 pb-2 mx-auto lead">
                                <div>
                                    <strong>Address: </strong>
                                </div>
                                <div className="ps-3">
                                    1675 Lakes Pkwy Ste 105
                                </div>
                                <div className="ps-3">
                                    Lawrenceville, GA 30043
                                </div>
                            </div>
                            <div className="col-lg-10 pb-2 mx-auto lead">
                                <strong>Phone: </strong>(770) 344-7300
                            </div>
                            <div className="col-lg-10 pb-2 mx-auto lead">
                                {/* <strong>E-mail: </strong><a href="mailto: support@reliancecardservices.com">support@reliancecardservices.com</a> */}
                                <strong>E-mail: </strong><a href="mailto: augh1974@gmail.com">augh1974@gmail.com</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-none d-lg-block col-5">
                        <img src="img/no-image.jpg" alt="" className="col-12" style={{maxHeight: "300px", width: "auto"}}/>
                    </div> */}
                </div>
            </div>
            <br></br>
        </div>
    )
}