import { useEffect } from "react";

export default function HardwarePeripherals() {
    useEffect(() =>{
        document.title = "Peripherals | Hardware";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                <img src="img/no-image.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Peripherals</strong></h1>
                            {/* <p className="white-text ms-4 col-10">
                                Reliance Card Services has been a partner with one of the leading providers of payment processing solutions, specializing in Independent Gas Stations, Fleet and Convenience stores. 
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button">Contact Us</button>
                            </div> */}
                        </div>
                    </span>
                </div>
            </div>
            <div className="mb-0 container">
                <br></br>
                <br></br>
                <br></br>

                <div className="mx-auto row text-center">
                    <h2 className="display-3">Coming Soon...</h2>
                </div>
                <br></br>
                <br></br>
                <br></br>

            </div>
        </div>
    )
}