// import ImgSlideshow from "../imgSlideshow";

import { useEffect } from "react";
import SmIconOpenContentList from "../SmIconOpenContentList"

export default function POSRelianceLiquor() {
    useEffect(() =>{
        document.title = "Reliance Liquor | POS Solutions";
    }, []);
    const contentArray = [
        {
            column: [
                {
                    id: 1,
                    title: "Fast Payment",
                    content:"Enjoy quick and smooth non-cash transactions that keep your customers happy and your business flowing.",
                    list: [],
                    image: "img/icon/payment-icon.png"
                },
                {
                    id: 2,
                    title: "Detailed Cashier Reports",
                    content:"With our software that can process all sales data in seconds, ReliancePOS can produce many kinds of reports. By tracking sales by various factors such as item, employee, time of day, promotion, and more, it can help businesses evaluate their store’s performance and identify areas for improvement.",
                    list: [],
                    image: "img/icon/sales-recording-and-reporting-icon.png"
                },
                {
                    id: 3,
                    title: "eCommerce Integration",
                    content:"Easy to work with Uber Eats & Grubhub",
                    list: [],
                    image: "img/icon/online-store-icon.png"
                },
                {
                    id: 4,
                    title: "Coupons, Bundled Deals, and Promotions",
                    content:"Change pricing and add specific deals with ease. All changes will be reflected in inventory and sales reports.",
                    list: [],
                    image: "img/icon/promotions-icon.png"
                },
                {
                    id: 5,
                    title: "Age Verification Reminders",
                    content:"With our POS, you can easily verify your customer’s age by scanning their driver’s license or ID card. It will automatically compute how old they are and check if they can legally drink alcohol. This way, you can avoid mistakes and confusion when serving your guests.",
                    list: [],
                    image: "img/icon/inventory-icon.png"
                }
            ]
        },
        {
            column: [
                {
                    id: 6,
                    title: "Liquor Store Customer Loyalty Program",
                    content:"Reliance POS understands the value of loyal customers for liquor stores. That’s why it offers seamless integration with top-notch customer loyalty programs tailored for your business. With your new point of sale, you can delight your best customers and keep them coming back",
                    list: [],
                    image: "img/icon/customer-loyalty.png"
                },
                {
                    id: 7,
                    title: "Case Breaks and Mix & Match Features",
                    content:"Reliance POS is a great tool for liquor stores that offer frequent deals and discounts. It can instantly update the price and inventory of any item on promotion. It can also analyze the product patterns and help you decide which items to promote more. Reliance POS supports your business and your marketing strategy.",
                    list: [],
                    image: "img/icon/case-break-and-mix-and-match-icon.png"
                },
                {
                    id: 8,
                    title: "Inventory Tracking with Handheld Devices",
                    content:"Managing inventory can be a huge challenge for most liquor shops. But with our POS software, it becomes easy and convenient. You can update your inventory counts instantly across all POSs with a simple scan. And, it can all be done with a light handheld scanner, too.",
                    list: [],
                    image: "img/icon/inventory-warehouse-icon.png"
                },
                {
                    id: 9,
                    title: "Retail Risk Management",
                    content:"Do not allow cashiers to cancel a whole sale after it is done. This is how cash is often stolen.",
                    list: [
                        {
                            subtitle: "Receipt Cancellation",
                            subcontent: "Some cashiers may delete items from a receipt if the customer pays with cash. Reliance POS blocks this action."
                        },
                        {
                            subtitle: "Cash Drawer Closing",
                            subcontent: "Make sure that cashiers close the cash drawer after every sale and do not perform any other POS tasks with an open drawer."
                        },
                        {
                            subtitle: "Line Item Voids",
                            subcontent: "You can customize your settings to control who can void a single item on a receipt and require a password for that."
                        },
                        {
                            subtitle: "Blind Cash Drops",
                            subcontent: "Prevent employees from knowing the amount of cash they have to deposit before they tally their cash register. This will help you detect any inconsistencies."
                        }
                    ],
                    image: "img/icon/employee-management-icon.png"
                },
                {
                    id: 10,
                    title: "",
                    content:"",
                    list: [],
                    image: ""
                }
            ]
        }
    ]
    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <br></br>
            <h3 className="colored-text ps-4 ms-3 pb-2">LIQUOR STORE</h3>
            <div className="row col-12 ps-5 ms-4 pb-0">
                <p className="col-md-2 col-sm-5"><a href="#reliance-liquor" className="text-decoration-none colored-text"><strong>Reliance Liquor</strong></a></p>
                <p className="col-md-2 col-sm-5"><a href="/POS-Korona" className="text-decoration-none text-dark"><u>Korona POS</u></a></p>
            </div>
            {/* <br></br> */}
            <div>
                <div className="relative-parent">
                    <div className="row col-12 center-header colored-bg header">
                        <div className="header-vertical-align col-lg-7 col-md-12">
                            <span className="">
                                <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                    <h1 className="white-text pb-2"><strong>Reliance Liquor</strong></h1>
                                    <p className="white-text ms-4 col-lg-9 col-10">
                                        Reliance Liquor POS is the ultimate destination for all your liquor store needs. 
                                        Whether you need to order, receive, price, or manage your inventory, we make it easier 
                                        than ever with our advanced inventory management system. You can also enjoy our case break 
                                        and mix & match options, which let you customize your deals and promotions. And that's not all. 
                                        {/* We also offer amazing promotions, such as coupons 
                                        and bundle deals, to reward our loyal customers. You can also join our customer loyalty 
                                        program and earn points for every purchase. Finally, our employee management and permissions 
                                        system ensures a smooth and efficient operation. Reliance Liquor is more than just a liquor 
                                        store POS. It's a success experience. Call us today and find out for yourself. */}
                                    </p>
                                    <div className="">
                                        <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <img src="img/liquor-bottles.jpg" alt="reliance-liquor" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                    </div>
                    <img src="img/reliance-liquor-screen.PNG" alt="reliance liquor screen" className="header-floating-screen floating-absolute d-none d-lg-block"/>
                </div>
                <div className="d-lg-none col-5 mx-auto">
                    <img src="img/reliance-liquor-screen.PNG" alt="reliance liquor screen" className="col-12"/>
                </div>
                <div className="d-none d-lg-block">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>

                <div className="container mx-auto pt-4">
                    <h3 className="mx-auto col-md-9 col-lg-8 col-xl-7 col-xxl-7 text-center dark-blue-text">Powerful, All-in-One Liquor Store Solutions</h3>
                    <h5 className="row mx-auto col-10 text-center lead">With our custom pos system, you get more than just a payment solution. You get a complete liquor store management system that is rated top-notch by our customers.</h5>
                </div>
                <br></br>
                {/* <hr className="mx-auto" width="60%"/> */}
                <div className="col-12 pt-5 ps-0 pe-0 m-0 mx-auto">
                    <div className="row mx-auto">
                        <div className="col-lg-6 col-md-12 ms-0 me-0 mx-auto row">
                            <div className="col-md-6 mx-auto">
                                <img src="img/reliance-liquor-payment-screen.PNG" alt="" className="img-fluid"/>
                            </div>
                            {/* <ImgSlideshow imagesArray={["img/image1.png", "img/image2.png", "img/image3.png"]}/> */}
                            <div className="col-md-6 pt-md-2 pb-2">
                                <h5 className="dark-blue-text"><strong>Payment</strong></h5>
                                <ul>
                                    <li>
                                        <div>
                                            <strong>Intuitive Design</strong>
                                            <div>With our user-friendly design, Reliance POS simplifies staff training. No
                                            need to waste time on stressful training sessions.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Credit and Debit Cards</strong>
                                            <div>Accept credit or debit transactions with EMV-chips and automated prompts to ensure secure payments.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Mobile and Contactless</strong>
                                            <div>Enable customers to pay with any contemporary option, such as
                                            NFC mobile payments with Apple Pay or Android Pay.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Split Payments</strong>
                                            <div>Cashiers can easily divide payments however the customer requests for any transaction.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Fast Payment Processing</strong>
                                            <div>Get fast transaction speeds so that all non-cash payments are processes efficiently and your lines keep moving.</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 ms-0 me-0 mx-auto row">
                            <div className="col-md-6 mx-auto">
                                <img src="img/reliance-liquor-inventory-screen.PNG" alt="" className="img-fluid"/>
                            </div>                            
                            <div className="col-md-6 pt-2 pb-2">
                                <h5 className="dark-blue-text"><strong>Inventory Management</strong></h5>
                                <ul>
                                    <li>
                                        <div>
                                            <strong>Stock Notifications</strong>
                                            <div>Keep track of your stock levels, receive alerts for low or excess
                                            inventory, and update your records from your POS system.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Receiving Products</strong>
                                            <div>Quickly and accurately scan incoming shipments into your inventory
                                            using a barcode scanner.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Product Performance</strong>
                                            <div>Evaluate how well each product is selling, how much profit it
                                            generates, and how often it needs to be reordered with a simple click.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Labeling and Pricing</strong>
                                            <div>Generate and print labels and price tags for your products directly
                                            from your inventory software.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Mobile Inventory</strong>
                                            <div>Manage your inventory using the Reliance Handheld Device, a portable
                                            and versatile tool.</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto pt-5 pb-5">
                        <div className="col-lg-6 mx-auto ms-0 me-0 row">
                            <div className="col-md-6 mx-auto">
                                <img src="img/reliance-liquor-report-screen.PNG" alt="" className="img-fluid"/>
                            </div>                            
                            <div className="col-md-6 pt-2 pb-2">
                                <h5 className="dark-blue-text"><strong>Detailed Cashier Reports</strong></h5>
                                <ul>
                                    <li>
                                        <div>
                                            <strong>Detailed Insights on Sales</strong>
                                            <div>Track your profits and revenue with ease and accuracy.
                                            You can group your reports by product or by store to see how each item or location is
                                            performing.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Comparing Data Across Time Periods</strong>
                                            <div>You can also compare your sales over different
                                            time periods to measure your growth and identify areas for improvement.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Average Ticket</strong>
                                            <div>Find out your average transaction amount by day or by employee to
                                            evaluate your customer service and pricing strategy.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Turnover Data</strong>
                                            <div>Learn your turnover rate to see how fast your products are selling and
                                            how to adjust your inventory accordingly.</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <strong>Return Data</strong>
                                            <div>Generate return rate reports to spot any products that are causing
                                            customer dissatisfaction or quality issues.</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 ms-0 me-0 mx-auto row">
                            <div className="col-md-6 mx-auto">
                                <img src="img/reliance-liquor-employee-screen.PNG" alt="" className="img-fluid"/>
                            </div>
                            <div className="col-md-6 pt-2 pb-2">
                                <h5 className="dark-blue-text"><strong>Employee Management & POS Permissions</strong></h5>
                                <ul>
                                    <li>
                                        {/* <div> */}
                                            {/* <strong>Profits and Revenue</strong> */}
                                            <div>Control the access of each employee according to their role and keep track of their
                                            transactional activity with shift reports.</div>
                                        {/* </div> */}
                                    </li>
                                    <li>
                                        {/* <div> */}
                                            {/* <strong>Time-Based Comparisons</strong> */}
                                            <div>Time Clock ~ Simplify your payroll process by managing all your time clock data in your
                                            POS. Employees can easily clock in and out using the system.</div>
                                        {/* </div> */}
                                    </li>
                                </ul>
                            </div>    
                        </div>
                    </div>
                </div>
                <br></br>
                <div>
                    <h4 className="ps-4 pb-4">Features</h4>
                    <div className="row ps-4 pe-5">
                        <div className="col-lg-8 col-md-12 ps-5">
                            <SmIconOpenContentList contentList={contentArray}/>
                        </div>
                        <img src="img/liquor-store1.jpg" alt="features" className="col-lg-3 object-fit-30 rounded-borders d-none d-lg-block"/>
                    </div>
                </div>
                <br></br>
            </div>
            <br></br>
            <br></br>
        </div>
    )
}