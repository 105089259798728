import { Link, Outlet } from "react-router-dom";
import Footer from "./Footer";
import DropdownMenu from "../DropdownMenu";
import { useState } from "react";

export default function Root() {
  const [open, setOpen] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const handleClick = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  return (
    <div className="ms-xl-2 me-xl-2 ms-md-2 me-md-2 ms-sm-0 me-sm-0">
        <nav className="navbar navbar-expand-lg ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 ">
            <div className="container-fluid">
                <Link to="/" className="active navbar-brand"><img src="img/transparent-pos-logo.png" alt="reliance-logo" className="logo"/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto mb-2 mb-lg-0 navi">
                        <li className="nav-item dropdown pe-xl-5">
                            
                            
                        {/* <div
                            className="menu"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            >
                            <button>POS Solutions</button>
                            {isDropdownVisible && <DropdownMenu index={0}/>}
                        </div> */}
                            <div 
                                onMouseEnter ={() => {
                                    handleMouseEnter()
                                    setOpen(0)
                                }}
                                onMouseLeave={() => {
                                    handleMouseLeave()
                                    setOpen(null)
                                }}
                                onClick={handleClick}
                            >                    
                                <a className="nav-link dropdown-toggle" href="/" id="posDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    POS Solutions
                                </a>  
                                {(isDropdownVisible && open === 0) && <DropdownMenu index={0}/>}
                                </div>

                            {/* <a className="nav-link dropdown-toggle" href="/" id="posDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                POS Solutions
                            </a>                            
                            <ul className="dropdown-menu ps-1 pe-1" aria-labelledby="posDropdown">
                                <li><a href="/POS-RelianceLiquor" className="nav-link dropdown-item">Liquor Store</a></li>
                                <li><a href="/POS-Tonic" className="nav-link dropdown-item">Restaurant</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Retail Store</a></li>
                                <li><a href="/POS-GasStation" className="nav-link dropdown-item">Gas Station</a></li>
                                <li><a href="/POS-DPTCDP" className="nav-link dropdown-item">True Cash Discount POS</a></li>
                            </ul> */}
                        </li>
                        <li className="nav-item dropdown ps-xl-5 pe-xl-5">
                            {/* <a className="nav-link dropdown-toggle" href="/" id="hardwareDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Hardware
                            </a>
                            <ul className="dropdown-menu ps-1 pe-1" aria-labelledby="hardwareDropdown">
                                <li><a href="/" className="nav-link dropdown-item disabled">Reliance I-5 Station</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Reliance I-5 All-in-One Station</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Reliance I-5 Convenience Station</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Clover Station</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Peripherals</a></li>
                            </ul> */}
                            <div 
                                onMouseEnter ={() => {
                                    handleMouseEnter()
                                    setOpen(1)
                                }}
                                onMouseLeave={() => {
                                    handleMouseLeave()
                                    setOpen(null)
                                }} onClick={handleClick}>                    
                            <a className="nav-link dropdown-toggle" href="/" id="posDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Hardware
                            </a>  
                            {(isDropdownVisible && open === 1 ) && <DropdownMenu index={1}/>}
                            </div>
                        </li>
                        <li className="nav-item dropdown ps-xl-5 pe-xl-5">
                            {/* <a className="nav-link dropdown-toggle" href="/" id="paymentSolutionsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Payment Solutions
                            </a>
                            <ul className="dropdown-menu ps-1 pe-1" aria-labelledby="paymentSolutionsDropdown">
                                <li><a href="/Payment-ValorPaytech" className="nav-link dropdown-item">Valor PayTech</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">PAX Technology</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">FD Terminals</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Dejavoo Terminals</a></li>
                                <li><a href="/Payment-TCDP" className="nav-link dropdown-item">Dual Pricing Cash Discount</a></li>
                            </ul> */}
                            <div 
                                onMouseEnter ={() => {
                                    handleMouseEnter()
                                    setOpen(2)
                                }}
                                onMouseLeave={() => {
                                    handleMouseLeave()
                                    setOpen(null)
                                }} onClick={handleClick}>                    
                            <a className="nav-link dropdown-toggle" href="/" id="posDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Payment Solutions
                            </a>  
                            {(isDropdownVisible && open === 2 ) && <DropdownMenu index={2}/>}
                            </div>
                        </li>
                        <li className="nav-item dropdown ps-xl-5 pe-xl-5">
                            {/* <a className="nav-link dropdown-toggle" href="/" id="contactDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Contact Us
                            </a>
                            <ul className="dropdown-menu ps-1 pe-1" aria-labelledby="contactDropdown">
                                <li><a href="/" className="nav-link dropdown-item disabled">Company Info</a></li>
                                <li><a href="/" className="nav-link dropdown-item disabled">Contact Us</a></li> 
                            </ul> */}
                            <div 
                                onMouseEnter ={() => {
                                    handleMouseEnter()
                                    setOpen(3)
                                }}
                                onMouseLeave={() => {
                                    handleMouseLeave()
                                    setOpen(null)
                                }} onClick={handleClick}>                    
                            <a className="nav-link dropdown-toggle" href="/" id="posDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Contact
                            </a>  
                            {(isDropdownVisible && open === 3 ) && <DropdownMenu index={3}/>}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <Outlet />
        <Footer />
    </div>
  );
}