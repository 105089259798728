import { useEffect } from "react";

export default function DualPricingTCDP() {
    useEffect(() =>{
        document.title = "True Cash Discount POS";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                <img src="img/zero-fee-pos.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                {/* <div className="col-6 ms-5 ps-5 pt-5 mt-5 mx-auto">
                    <h1><strong className="white-text pt-5">Dual Pricing True Cash Discount Program</strong></h1>
                    <p className="ms-4 pt-3 pe-5 col-10 white-text">
                        Dual Pricing Cash Discount program is a unique pricing strategy to give customers the choice to
                        pay with cash instead of a credit card, thereby avoiding credit card processing fees. It’s legal in
                        all 50 states and it’s not just for gas stations. With ReliancePOS system, any type of business can
                        run a dual pricing program.
                    </p>
                    <button type="button" className="btn main-btn mt-5 ms-4 tertiary-button">Contact Us</button>
                </div> */}
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Dual Pricing True Cash Discount Program</strong></h1>
                            <p className="white-text ms-4 col-10">
                                Dual Pricing Cash Discount program is a unique pricing strategy to give customers the choice to
                                pay with cash instead of a credit card, thereby avoiding credit card processing fees. It’s legal in
                                all 50 states and it’s not just for gas stations. With ReliancePOS system, any type of business can
                                run a dual pricing program.
                                {/* We also offer amazing promotions, such as coupons 
                                and bundle deals, to reward our loyal customers. You can also join our customer loyalty 
                                program and earn points for every purchase. Finally, our employee management and permissions 
                                system ensures a smooth and efficient operation. Reliance Liquor is more than just a liquor 
                                store POS. It's a success experience. Call us today and find out for yourself. */}
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="container">
                {/* <div className="col-12 pb-3 pt-5">
                    <h5 className="colored-text col-xl-11 pb-3">
                        <strong>ReliancePOS provides powerful True Cash Discount Dual Pricing features that are compliant and
                        convenient pricing solutions.</strong>
                    </h5>
                    <div className="col-xl-10 pb-2">
                        <strong>It provides full pricing transparency to customers, from the shelf
                        to the payment terminal.</strong>
                    </div>
                    <div className="pt-3 ps-5">
                        <div className="">
                            <div className="colored-text pe-3 float-left"><strong>✓</strong></div>
                            <p className="ps-4">Clearly display card vs cash pricing at the time of sale</p>
                        </div>
                        <div>
                            <div className="colored-text pe-3 float-left"><strong>✓</strong></div>
                            <p className="ps-4">Receipt shows discount applied</p>
                        </div>
                        <div>
                            <div className="colored-text pe-3 float-left"><strong>✓</strong></div>
                            <p className="ps-4">Dual pricing labels are generated for the shelf</p>
                        </div>
                    </div>
                    <div className="pt-3">
                        Our team will work with you to determine the pricing model that makes the most sense for your
                        business.
                    </div>
                </div>
                <img src="img/dual-pricing-new.jpg" alt="" className="mx-auto rounded-borders col-12 pt-4 pb-5 object-fit"/> */}
                <div className="row pt-4 pb-5 mt-4 mx-auto">
                    <div className="ps-lg-5 col-xl-5 col-md-12 pb-3 pt-xl-5">
                        <h5 className="colored-text col-xl-11 pb-3">
                            <strong>ReliancePOS provides powerful True Cash Discount Dual Pricing features that are compliant and
                            convenient pricing solutions.</strong>
                        </h5>
                        <div className="col-xl-10 pb-2">
                            <strong>It provides full pricing transparency to customers, from the shelf
                            to the payment terminal.</strong>
                        </div>
                        <div className="pt-3 ps-4">
                            <div className="">
                                <div className="colored-text pe-3 float-left"><strong>✓</strong></div>
                                <p className="ps-4">Clearly display card vs cash pricing at the time of sale</p>
                            </div>
                            <div>
                                <div className="colored-text pe-3 float-left"><strong>✓</strong></div>
                                <p className="ps-4">Receipt shows discount applied</p>
                            </div>
                            <div>
                                <div className="colored-text pe-3 float-left"><strong>✓</strong></div>
                                <p className="ps-4">Dual pricing labels are generated for the shelf</p>
                            </div>
                        </div>
                        <div className="pt-3">
                            Our team will work with you to determine the pricing model that makes the most sense for your
                            business.
                        </div>
                    </div>
                    <img src="img/dual-pricing-new.jpg" alt="" className="mx-auto rounded-borders col-xl-7 object-fit pt-md-4 pt-xl-0"/>
                </div>
            </div>
        </div>
    )
}