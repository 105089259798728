import { useEffect } from "react";

export default function HardwareRelianceI5StationwithBP() {
    useEffect(() =>{
        document.title = "Reliance I5 Station with Built-in Printer | Hardware";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                <img src="img/reliance-i5-bp-white.png" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Reliance I5 Station with Built-in Printer </strong></h1>
                            <p className="white-text ms-4 col-10">
                                A new type of POS machine with a super compact all-in-one design and powerful
                                performance combined.
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="container">
                {/* <div className="col-10 col-md-6 mx-auto">
                    <img src="img/hardware2.png" alt="" className="col-12"/>
                </div> */}
                <br></br>
                <table className="col-10 mx-auto">
                    <tbody>
                        <tr>
                            <th className="p-2">CPU</th>
                            <td className="p-2">Intel Core I5</td>
                            <th className="p-2">RAM</th>
                            <td className="p-2">DDR3, 4GB, 8GB</td>
                        </tr>
                        <tr>
                            <th className="p-2">Storage</th>
                            <td className="p-2">Sandisk SSD 64G</td>
                            <th className="p-2">Operating System</th>
                            <td className="p-2">Window 10 Pro</td>
                        </tr>
                        <tr>
                            <th className="p-2">Display panel</th>
                            <td className="p-2">15" & 17" LED Back light, 1024x768 LVDS</td>
                            <th className="p-2">Touch Screen</th>
                            <td className="p-2">Capacitive touch screen (resistive touch optional)</td>
                        </tr>
                        <tr>
                            <th className="p-2">Customer Display</th>
                            <td className="p-2">LCD 12.1"</td>
                            <th className="p-2">Printer Port</th>
                            <td className="p-2">USB and RS232</td>
                        </tr>
                        <tr>
                            <th className="p-2">Power Supply</th>
                            <td className="p-2">DC12V, 5A</td>
                            <th className="p-2">Printer</th>
                            <td className="p-2">80 mm</td>
                        </tr>
                    </tbody>
                </table>

                <br></br>
            </div>
        </div>
    )
}