import { useEffect } from "react";

export default function HardwareCloverStation() {
    useEffect(() =>{
        document.title = "Clover Station | Hardware";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                <img src="img/hardware-clover-main.png" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Clover Station</strong></h1>
                            <p className="white-text ms-4 col-12 lead">
                            A point of sale system designed to make things easy
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div>
                    <div className="light-gray-bg pt-5 pb-5 ps-4 pe-4">
                        <div className="container mx-auto pt-3">
                            <div className="row">
                                <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                    <img src="img/station-solo-diagram.jpg" alt="station solo diagram" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                                </div>
                                <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                    <h3 className="colored-text pb-4 display-5"><strong>Station Solo</strong></h3>
                                    <div className="lead pb-3 ps-3"><strong><u>Product Details</u></strong></div>
                                    <div className="ps-3">
                                        <div className="pb-2"><strong>Screen: </strong>14″ high-definition display, tiltable</div>
                                        <div className="pb-2"><strong>Receipt printer (Optional): </strong>High-speed thermal dot receipt printer</div>
                                        <div className="pb-2"><strong>Camera & Scanner: </strong>Dual 5 mega-pixel cameras with scanning software</div>
                                        <div className="pb-2"><strong>Connectivity: </strong>WiFi, ethernet, and 4G/LTE connectivity</div>
                                        <div className="pb-2"><strong>Cash drawer (Optional): </strong>Includes a bill tray, coin tray, two keys, and a security cable</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                    <div className="pt-5 pb-5 ps-4 pe-4">
                        <div className="container mx-auto pt-3">
                            <div className="row">
                                <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                    <img src="img/station-duo-diagram.jpg" alt="station duo diagram" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                                </div>
                                <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                    <h3 className="colored-text pb-4 display-5"><strong>Station Duo</strong></h3>
                                    <div className="lead pb-3 ps-3"><strong><u>Product Details</u></strong></div>
                                    <div className="ps-3">
                                        <div className="pb-2"><strong>Payments: </strong>Accepts chip, swipe, and contactless payments including Apple Pay®, Google Pay® and Samsung Pay®</div>
                                        <div className="pb-2"><strong>Screen: </strong>14" high-definition merchant-facing touch screen and 8" customer-facing touch screen</div>
                                        <div className="pb-2"><strong>Receipt printer: </strong>High-speed thermal dot receipt printer</div>
                                        <div className="pb-2"><strong>Security: </strong>Clover Security end-to-end encryption</div>
                                        <div className="pb-2"><strong>Camera & Scanner: </strong>Dual 5 mega-pixel cameras with scanning software</div>
                                        <div className="pb-2"><strong>Connectivity: </strong>WiFi, ethernet, and 4G/LTE</div>
                                        <div className="pb-2"><strong>Cash drawer: </strong>Includes a bill tray, coin tray, two keys, and a security cable</div>
                                        <div className="pb-2"><strong>PIN Pad: </strong>Virtual keypad for PIN entry</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="light-gray-bg pt-5 pb-5 ps-4 pe-4">
                        <div className="container mx-auto pt-3">
                            <div className="row">
                                <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                    <img src="img/flex-diagram.jpg" alt="flex diagram" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                                </div>
                                <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                    <h3 className="colored-text pb-4 display-5"><strong>Flex</strong></h3>
                                    <div className="lead pb-3 ps-3"><strong><u>Product Details</u></strong></div>
                                    <div className="ps-3">
                                        <div className="pb-2"><strong>Payments: </strong>Accepts chip, swipe, and contactless payments including Apple Pay®, Google Pay® and Samsung Pay®</div>
                                        <div className="pb-2"><strong>Screen: </strong>Nearly 6" LCD color touch screen</div>
                                        <div className="pb-2"><strong>Receipts: </strong>Built-in thermal dot printer and can email or text digital receipt</div>
                                        <div className="pb-2"><strong>Security: </strong>Clover Security end-to-end encryption</div>
                                        <div className="pb-2"><strong>Camera & Scanner: </strong>1D/2D barcode scanner/camera</div>
                                        <div className="pb-2"><strong>Connectivity: </strong>WiFi, LTE</div>
                                        <div className="pb-2"><strong>Battery Life: </strong>At least 8 hours of use for a typical SMB</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-5 pb-5 ps-4 pe-4">
                        <div className="container mx-auto pt-3">
                            <div className="row">
                                <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                    <img src="img/mini-diagram.jpg" alt="mini diagram" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                                </div>
                                <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                    <h3 className="colored-text pb-4 display-5"><strong>Mini</strong></h3>
                                    <div className="lead pb-3 ps-3"><strong><u>Product Details</u></strong></div>
                                    <div className="ps-3">
                                        <div className="pb-2"><strong>Payments: </strong>Accepts chip, swipe, and contactless payments</div>
                                        <div className="pb-2"><strong>Screen: </strong>8" LCD color touch screen</div>
                                        <div className="pb-2"><strong>Receipt printer: </strong>Built-in thermal dot receipt printer</div>
                                        <div className="pb-2"><strong>Security: </strong>Clover Security end-to-end encryption</div>
                                        <div className="pb-2"><strong>Connectivity: </strong>WiFi, ethernet, and LTE connectivity</div>
                                        <div className="pb-2"><strong>Cash drawer: </strong>Optional add-on</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}