import { useState } from "react";

export default function SmIconOpenContentList({contentList}) {
    const [openedContent, setOpenedContent] = useState(0);
    return (
        <div className="row">
            {contentList.map((object) => {
            return (
                <div key={ object === contentList[0] ? "first" : "second"} className="col-md-6">
                    <div key={object.column[0].id} className="pb-3">
                        <img src={object.column[0].image} alt={object.column[0].title} className="icon float-left pb-3"/>
                        <p 
                            className="ps-5 fw-medium dark-blue-text pointer"
                            width="50%" 
                            onClick={() => {
                                if(openedContent !== object.column[0].id)
                                {
                                    setOpenedContent(object.column[0].id)
                                }
                                else {
                                    setOpenedContent(0)
                                }
                            }}
                        >
                            {object.column[0].title}
                        </p>
                        {openedContent === object.column[0].id && (
                            <div className="ps-5">
                                {object.column[0].content}
                                <ul>
                                    {object.column[0].list.map((item) => {
                                        return (
                                            <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div key={object.column[1].id} className="pb-3">
                        <img src={object.column[1].image} alt={object.column[1].title} className="icon float-left pb-3"/>
                        <p 
                            className="ps-5 fw-medium dark-blue-text pointer"
                            width="50%" 
                            onClick={() => {
                                if(openedContent !== object.column[1].id)
                                {
                                    setOpenedContent(object.column[1].id)
                                }
                                else {
                                    setOpenedContent(0)
                                }
                            }}
                        >
                            {object.column[1].title}
                        </p>
                        {openedContent === object.column[1].id && (
                            <div className="ps-5">
                                {object.column[1].content}
                                <ul>
                                    {object.column[1].list.map((item) => {
                                        return (
                                            <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div key={object.column[2].id} className="pb-3">
                        <img src={object.column[2].image} alt={object.column[2].title} className="icon float-left pb-3"/>
                        <p 
                            className="ps-5 fw-medium dark-blue-text pointer"
                            width="50%" 
                            onClick={() => {
                                if(openedContent !== object.column[2].id)
                                {
                                    setOpenedContent(object.column[2].id)
                                }
                                else {
                                    setOpenedContent(0)
                                }
                            }}
                        >
                            {object.column[2].title}
                        </p>
                        {openedContent === object.column[2].id && (
                            <div className="ps-5">
                                {object.column[2].content}
                                <ul>
                                    {object.column[2].list.map((item) => {
                                        return (
                                            <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div key={object.column[3].id} className="pb-3">
                        <img src={object.column[3].image} alt={object.column[3].title} className="icon float-left pb-3"/>
                        <p 
                            className="ps-5 fw-medium dark-blue-text pointer"
                            width="50%" 
                            onClick={() => {
                                if(openedContent !== object.column[3].id)
                                {
                                    setOpenedContent(object.column[3].id)
                                }
                                else {
                                    setOpenedContent(0)
                                }
                            }}
                        >
                            {object.column[3].title}
                        </p>
                        {openedContent === object.column[3].id && (
                            <div className="ps-5">
                                {object.column[3].content}
                                <ul>
                                    {object.column[3].list.map((item) => {
                                        return (
                                            <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div key={object.column[4].id} className="pb-3">
                        <img src={object.column[4].image} alt={object.column[4].title} className="icon float-left pb-3"/>
                        <p 
                            className="ps-5 fw-medium dark-blue-text pointer"
                            width="50%" 
                            onClick={() => {
                                if(openedContent !== object.column[4].id)
                                {
                                    setOpenedContent(object.column[4].id)
                                }
                                else {
                                    setOpenedContent(0)
                                }
                            }}
                        >
                            {object.column[4].title}
                        </p>                        {openedContent === object.column[4].id && (
                            <div className="ps-5">
                                {object.column[4].content}
                                <ul>
                                    {object.column[4].list.map((item) => {
                                        return (
                                            <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )
            })}
        </div>
    )
}



// {contentList.map((object) => {
//     return (
//         <div className="col-lg-12 col-md-12">
//             <div key={object.pair[0].id} className="col-5 pb-3">
//                 <img src={object.pair[0].image} alt={object.pair[0].title} className="icon float-left pb-3"/>
//                 <p className=" ps-5 fw-medium" width="50%" onClick={() => {setOpenedContent(object.pair[0].id)}}>{object.pair[0].title}</p>
//                 {openedContent === object.pair[0].id && (
//                     <div className="ps-5">
//                         {object.pair[0].content}
//                         <ul>
//                             {object.pair[0].list.map((item) => {
//                                 return (
//                                     <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
//                                 )
//                             })}
//                         </ul>
//                     </div>
//                 )}
//             </div>
//             <div key={object.pair[1].id} className="col-5 pb-3">
//                 <img src={object.pair[1].image} alt={object.pair[1].title} className="icon float-left pb-3"/>
//                 <p className="ps-5 fw-medium" width="50%" onClick={() => {setOpenedContent(object.pair[1].id)}}>{object.pair[1].title}</p>
//                 {openedContent === object.pair[1].id && (
//                     <div className="ps-5">
//                         {object.pair[1].content}
//                         <ul>
//                             {object.pair[1].list.map((item) => {
//                                 return (
//                                     <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong>: {item.subcontent}</li>
//                                 )
//                             })}
//                         </ul>
//                     </div>
//                 )}
//             </div>
//         </div>
//     )
// })}