import { useState } from "react";

export default function OpenContentList({contentList, color, line, isOpen}) {
    const [openedContent, setOpenedContent] = useState(isOpen);

    return (
        <div>
            {contentList.map((object) => {
                return (
                    <div key={object.id}>
                        <button 
                            type="button" 
                            className={`btn btn-link text-decoration-none ${ color === "orange" ? "orange-text" : "text-dark"}`} 
                            onClick={() => 
                                {
                                    if(openedContent !== object.id){
                                        setOpenedContent(object.id)
                                    }
                                    else {
                                        setOpenedContent(0)
                                    }
                                }}
                        >
                            <h3 className="fw-normal">{object.title}</h3>
                        </button>
                        {openedContent === object.id && (
                            <div className="row pb-3">
                                <img src={object.image} alt={object.title} className="rounded-borders d-lg-none"/>
                                <div className="col-lg-6 pt-3 pt-lg-0 ps-lg-5">
                                    {object.content}
                                    <ul>
                                        {object.list.map((item) => {
                                            return (
                                                <li className="pt-2" key={item.subtitle}><strong>{item.subtitle}</strong> {item.subcontent}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="ps-3 col-6 d-none d-lg-block mt-lg-5 mt-xl-0">
                                    <img src={object.image} alt={object.title} className="col-12 rounded-borders"/>
                                </div>
                            </div>
                        )}
                        <hr className="m-1" width={line}></hr>
                    </div>
                )
            })}
        </div>
    )
}