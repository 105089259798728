import { useEffect } from "react";

export default function POSRetail() {
    useEffect(() =>{
        document.title = "Retail | POS Solutions";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                <img src="img/reliance-retail-img.JPG" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Retail</strong></h1>
                            {/* <p className="white-text ms-4 col-10">
                                Reliance Card Services has been a partner with one of the leading providers of payment processing solutions, specializing in Independent Gas Stations, Fleet and Convenience stores. 
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button">Contact Us</button>
                            </div> */}
                            <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                        </div>
                    </span>
                </div>
            </div>
            {/* <div className="mb-0 container"> */}
                <br></br>
                <br></br>
                <br></br>

                <div className="mx-auto row text-center p-3">
                    <h3 className="dark-blue-text">All-in-One Retail POS System for Your Store. </h3>
                    <h5 className="lead colored-text">The best custom-tailored, retail point-of-sale solutions that meet all small business needs.</h5>
                    <p className="lead col-lg-9 mx-auto">Our POS system delivers fast, easy-to-use and affordable point of sale that allows the merchant to effectively manage their business to its fullest potential. The Reliance POS system will help you run your business better and easier.</p>
                </div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h4 className="ps-lg-5 dark-blue-text">Hardware</h4>
                            <div className="ps-5">
                                <ul>
                                    <li>Compact & all-in-one system</li>
                                    <li>Touch Screen Monitor</li>
                                    <li>Customer Display / Pole Display</li>
                                    <li>Heavy Duty Cash Drawer</li>
                                    <li>Integrated Payment Device capable of EMV, Apple Pay, Signature Capture</li>
                                </ul>
                            </div>
                        </div>
                        <br></br>
                        <div className="col-12 col-lg-6 pt-3 pt-lg-0 -">
                            <h4 className="ps-lg-5 dark-blue-text">Features</h4>
                            <div className="ps-5">
                                <ul>
                                    <li>Simple and fast checkout</li>
                                    <li>Flexible to meet the needs of various retail environments</li>
                                    <li>EBT & food stamps</li>
                                    <li>Scan items with barcode scanning</li>
                                    <li>Employee management with labor scheduler</li>
                                    <li>Reduce or eliminate theft</li>
                                    <li>Track & manage inventory</li>
                                    <li>Discount & coupon options</li>
                                    <li>Back office management reporting</li>
                                    <li>Customer tracking</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>

            {/* </div> */}
        </div>
    )
}