import { useEffect } from "react";

export default function PaymentDejavooTerminals () {
    useEffect(() =>{
        document.title = "Dejavoo Terminals Payment";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div>
                
                <div className="row col-12 center-header colored-bg header">
                    <div className="header-vertical-align col-xl-7 col-lg-6 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>Dejavoo Terminals</strong></h1>
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </span>
                    </div>
                    <div className="col-lg-6 col-xl-5 pe-0">
                        <img src="img/dejavoo-header.png" alt="valor paytech logo" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                    </div>
                </div>
                <div className="pt-5 pb-5 ps-4 pe-4">
                    <h3 className="ps-md-4 dark-blue-text pb-2">Countertop Terminals</h3>
                    <div className="container mx-auto pt-3">
                        <div className="row">
                            <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                <img src="img/dejavoo-Z8.jpg" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                <h4 className="colored-text">Z8 Tri Comm</h4>
                                {/* <div className="lead pb-2">SMART DESKTOP</div> */}
                                {/* <div>
                                </div> */}
                                <ul className="pt-3 pb-2">
                                    <li>EMV and NFC Contactless Built In – EMV 4.x L1 and L2 certified</li>
                                    <li>Tri-Comm capability (Dial, IP, & WiFi)</li>
                                    <li>Connect to WiFi easily, self discovering so the terminal finds all available Networks!</li>
                                    <li>2.4’’ Color LCD with backlight</li>
                                    <li>192 MB of Memory   (128 MB RAM / 64 MB DDR RAM)</li>
                                    <li>Model: The Vega 3000 is PCI PTS v4.x Certified</li>
                                    <li>Apple Pay, Samsung Pay, Google Wallet, Visa PayWave, MasterCard PayPass</li>
                                    <li>Display logo on the screen and print logo on the transaction receipts</li>
                                    <li>32bits 400MHz high speed microprocessor</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/Dejavoo_Z8-Z11-Countertop.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Brochure</a></button>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <hr width="50%" className="mx-auto"></hr>
                    <br></br>
                    <div className="container mx-auto pt-5">
                        <div className="row">
                            <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                <img src="img/dejavoo-Z11.jpg" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                <h4 className="colored-text">Z11 Tri Comm Touch Screen</h4>
                                {/* <div className="lead pb-2">RETAIL PIN PAD</div> */}
                                {/* <div>
                                </div> */}
                                <ul className="pt-3 pb-2">
                                    <li>EMV and NFC Contactless Built In – EMV 4.x L1 and L2 certified</li>
                                    <li>Tri-Comm capability (Dial, IP & WiFi)</li>
                                    <li>3.5” TFT LCD with PCT touch screen for signature on the screen</li>
                                    <li>2.4’’ Color LCD with backlight</li>
                                    <li>192 MB of Memory   (128 MB RAM / 64 MB DDR RAM)</li>
                                    <li>Model: The Vega 3000 is PCI PTS v4.x Certified</li>
                                    <li>Apple Pay, Samsung Pay, Google Wallet, Visa payWave, MasterCard PayPass,</li>
                                    <li>Display logo on the screen and print logo on the transaction receipts</li>
                                    <li>Scroll multiple images on the terminal display when in idle mode</li>
                                    <li>Ideal for signature capture and upload to the DeNovo business portal</li>
                                    <li>Great solution for SPIn integration to POS systems</li>
                                    <li>32bits 400MHz high speed microprocessor</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/Dejavoo_Z8-Z11-Countertop.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Brochure</a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="light-gray-bg pt-5 pb-5 ps-4 pe-4">
                    <h3 className="ps-md-4 dark-blue-text pb-2">PIN Pad Terminals</h3>
                    <div className="container mx-auto pt-3 pb-5">
                        <div className="row">
                            <div className="col-10 col-md-6 pe-md-3 ps-lg-5 ps-0 pe-0 mx-auto">
                                <img src="img/dejavoo-Z3.png" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-md-3 ps-0 pt-3 pt-md-0">
                                <h4 className="colored-text">Z3 PIN Pad Terminal</h4>
                                {/* <div className="lead pb-2">SMART DESKTOP</div> */}
                                {/* <div>
                                </div> */}
                                <ul className="pt-3 pb-2">
                                    <li>Built In NFC Contactless Reader</li>
                                    <li>EMV 4.x L1 and L2 Certified</li>
                                    <li>Ethernet connectivity</li>
                                    <li>32 bits high performance secure Microprocessor, @ 400MHz • 2.4” color LCD (320 × 240 pixels)</li>
                                    <li>High Security CPU</li>
                                    <li>Open Architecture Linux Operating System</li>
                                    <li>Model: The Vega 3000 is PCI PTS v4 x Certified</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/Dejavoo_Z3-Z6-PIN-Pad.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Brochure</a></button>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <hr width="50%" className="mx-auto"></hr>
                    <br></br>
                    <div className="container mx-auto pt-5">
                        <div className="row">
                            <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                <img src="img/dejavoo-Z6.jpg" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                <h4 className="colored-text">Z6 Pin Pad Terminal with Touch Screen</h4>
                               
                                <ul className="pt-3 pb-2">
                                    <li>Built-in NFC Contactless Reader</li>
                                    <li>EMV 4.x L1 and L2 Certified</li>
                                    <li>Ethernet connectivity</li>
                                    <li>32 bits high performance secure Microprocessor, @ 400MHz</li>
                                    <li>3.5” color Touch Screen (TFT-LCD 320×480 pixels)</li>
                                    <li>192 MB of Memory (128 MB RAM / 64 MB DDR RAM)</li>
                                    <li>High Security CPU</li>
                                    <li>Open Architecture Linux Operating System</li>
                                    <li>Model: The Vega 3000 is PCI PTS v4 x Certified</li>
                                    <li>Visa PayWave, MasterCard PayPass, American Express & JCB J/Speedy certified</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/Dejavoo_Z3-Z6-PIN-Pad.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Brochure</a></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}