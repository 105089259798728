import { useEffect } from "react";

export default function AboutUs() {
    useEffect(() =>{
        document.title = "About Us";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header pt-5 pb-5 part-colored-bg">
                    <div className="col-lg-7 col-md-12 pb-5">
                        <span className="">
                            <div className="ps-md-5 ps-4  mx-auto">
                                <h1 className="white-text"><strong>About Us</strong></h1>
                                <div className="">
                                    {/* <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button">Contact Us</button> */}
                                </div>
                            </div>
                        </span>
                    </div>
                    <img src="img/reliance-new-building.JPG" alt="solo station" className="col-10 mx-auto m-0 p-0 floating-image-no-shadow"/>
                </div>
            {/* <div className="row col-12 center-header colored-bg header">
                <div className="header-vertical-align col-xxl-8 col-xl-7 col-lg-6 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>About Us</strong></h1> 
                        </div>
                    </span>
                </div>
                <div className="col-lg-6 col-xl-5 col-xxl-4 pe-0">
                    <img src="img/no-image.jpg" alt="" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                </div>
            </div> */}
            <div className="container pb-5">
                <div className="col-12 pb-3 ps-4 ps-md-5 pt-5 pt-lg-0 pe-4 pe-lg-5">
                    <h5 className="colored-text pb-3">
                        <strong>Based in Metro Atlanta, GA, Reliance Card Services, LLC. provides all things POS and card payment processing.</strong>
                    </h5>
                    <div className="pb-2">
                        For the past two decades, we’ve proudly provided our services throughout the Southeastern region, benefiting our customers with some of the best services, equipment, programs, and prices in the industry.
                    </div>
                    <div className="pt-3">
                        Whether you’re an independent merchant or a large corporation, we have the right tools, experience, and relationship to help grow your business. Contact us and experience the difference in your business.
                    </div>
                </div>
                {/* <div className="row pt-4 pb-5 mt-4 mx-auto">
                    <div className=" col-12 col-lg-6">
                        <img src="img/RMS-building.jpg" alt="reliance-building" className="col-12"/>
                    </div>
                    <div className="col-12 pb-3 col-lg-6 ps-4 ps-md-5 pt-5 pt-lg-0 pe-4 pe-lg-5">
                        <h5 className="colored-text pb-3">
                            <strong>Based in Metro Atlanta, GA, Reliance Card Services, LLC. provides all things POS and card payment processing.</strong>
                        </h5>
                        <div className="pb-2">
                            For the past two decades, we’ve proudly provided our services throughout the Southeastern region, benefiting our customers with some of the best services, equipment, programs, and prices in the industry.
                        </div>
                        <div className="pt-3">
                            Whether you’re an independent merchant or a large corporation, we have the right tools, experience, and relationship to help grow your business. Contact us and experience the difference in your business.
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}