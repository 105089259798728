import { useEffect } from "react";

export default function PaymentFDTerminals () {
    useEffect(() =>{
        document.title = "FD Terminals Payment";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div>
                
                <div className="row col-12 center-header colored-bg header">
                    <div className="header-vertical-align col-xl-7 col-lg-6 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>FD 150 Terminal and RP10 PIN Pad</strong></h1>
                                <div className="white-text ms-4 col-10">
                                    <h4>Swipe, dip or tap</h4>
                                    <div className="ms-2">
                                        The FD150 is replacing the FD130 as the latest affordable terminal solution that 
                                        combines performance, reliability and ease of use in a compact, feature-rich device. 
                                        It offers faster processing and expanded memory with a familiar look and feel.
                                        -center text: FD150 benefits your business. Great fit for any restaurant or retailer.
                                    </div>
                                    <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-lg-6 col-xl-5 pe-0">
                        <img src="img/FD150-terminal.png" alt="valor paytech logo" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                    </div>
                </div>
                <div className="container mx-auto pt-5">
                    <h3 className="mx-auto col-md-9 col-lg-8 text-center dark-blue-text">FD150 benefits your business. Great fit for any restaurant or retailer.</h3>
                    <div className="row">
                        <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                            <img src="img/RP10-PIN-Pad.png" alt="" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width: "auto"}}/>
                        </div>
                        <div className="col-12 col-md-6 ps-3 pt-3 pt-lg-5 pt-md-0">
                            <h4 className="ps-md-4 pb-2 pt-3">Give Your Customers Peace of Mind with the RP10 Companion PIN Pad</h4>
                            <div className="container col-11 mx-auto pt-3">
                                <ul>
                                    <li>Allow customers to keep their card with them</li>
                                    <li>Ease concerns about unauthorized use of card</li>
                                    <li>Reduce physical contact with employees</li>
                                    <li>Support payment type of their choice including contactless, mobile wallets, PIN cards, gift cards and more</li>
                                    <li>Minimize security risks with PCI version 5 compliance</li>
                                </ul>
                                <div>All while speeding up the transaction process</div>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="light-gray-bg mx-auto pt-5 pb-5 ps-4 pe-4">
                    <h4 className="ps-md-4 dark-blue-text pb-2">FAQ</h4>
                    <div className="container col-11 mx-auto pt-3">
                        <div className="mb-2">
                            <div className="col-12">
                                <h5>How is the FD150 terminal different from the FD130?</h5>
                                <div className="ps-3">The FD150 has the following improvements over the FD130 terminal.</div>
                                <ul className="ps-5">
                                    <li>Faster, more advanced processor</li>
                                    <li>Double the memory</li>
                                    <li>PCI-PED version 5.x approved compared to 3.x</li>
                                </ul>
                            </div>
                            <div className="col-12 pt-3">
                                <h5>Can the FD150 support multi-merchant processing?</h5>
                                <div className="ps-3">
                                    Yes. This enable several business owners to process payments for more 
                                    than one merchant account using a single terminal.
                                </div>
                            </div>
                            <div className="col-12 pt-4">
                                <h5>Can the FD150 support Apple PayTM and Samsung Pay?</h5>
                                <div className="ps-3">
                                    Yes. It can accept NFC payments like Apple PayTM, Samsung Pay, and other popular mobile wallets or contactless cards.
                                </div>
                            </div>
                            <div className="col-12 pt-4">
                                <h5>Can the FD150 accept EBT?</h5>
                                <div className="ps-3">
                                    Yes. The FD150 accepts electronic benefits transfer (EBT) transactions.
                                </div>
                            </div>
                            <div className="col-12 pt-4">
                                <h5>Can I accept PIN debit on the FD150?</h5>
                                <div className="ps-3">
                                    Yes, the customer can enter their PIN on the FD150 itself or via the RP10 PIN Pad, when attached.
                                </div>
                            </div>
                            <div className="col-12 pt-4">
                                <h5>Do I need the RP10 PIN pad to operate the FD150?</h5>
                                <div className="ps-3">
                                    No. You can use the FD150 terminal independently and run credit and debit transactions without a PIN pad.
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                </div>

            </div>

        </div>
    )
}