import { useEffect } from "react";

export default function PrivacyPolicy() {
    useEffect(() =>{
        document.title = "Privacy Policy";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header pt-5 colored-bg">
                <div className="col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mx-auto pb-5">
                            <h1 className="white-text"><strong>Privacy Policy</strong></h1>
                        </div>
                    </span>
                </div>
            </div>
            <div className="container pb-5 pt-5">
                <div className="col-12 ms-3 me-3 pb-3 pe-4">
                    <div className="pb-4 ps-4">
                        Reliance Card Services (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;) is committed to protecting your privacy. This policy
                        outlines how we collect, use, and safeguard your personal information, specifically your phone number, when you interact with our SMS marketing service.
                    </div>
                    <h5 className="colored-text pb-2">
                        <strong>Text Messaging</strong>
                    </h5>
                    <div className="pb-2 ps-4">
                        - Collection: We collect your phone number only when you voluntarily provide it to us, such as
                        when you submit a merchant application or contact us through our advertising channels.
                    </div>
                    <div className="pb-2 ps-4">
                        - Opt-in/Opt-out: You can opt-in to receive messages by texting us &quot;YES&quot; or agreeing over the
                        phone or in person. To stop receiving messages, text &quot;STOP&quot; to the same number. Standard
                        message and data rates may apply.
                    </div>
                    <div className="pb-2 ps-4">
                        - Purpose: We use your phone number solely for important updates related to your account,
                        such as application confirmations, inquiry fulfillment, status changes, shipping notifications, and
                        technical / agent support.
                    </div>
                    <h5 className="colored-text pb-2">
                        <strong>Data Security</strong>
                    </h5>
                    <div className="pb-2 ps-4">
                        We prioritize the security of your personal information. We implement strong security measures
                        to protect your data from unauthorized access, disclosure, alteration, or destruction. We limit
                        access to your personal information to authorized personnel only, using role-based access
                        controls. We provide regular security awareness training to our employees to ensure they
                        understand their role in protecting your data.
                    </div>
                    <h5 className="colored-text pb-2">
                        <strong>Data Sharing</strong>
                    </h5>
                    <div className="pb-2 ps-4">
                        We do not sell, rent, loan, trade, lease, or otherwise transfer your phone number or any other
                        personal information to third parties for profit. We may share your information with trusted third-
                        party service providers who assist us in delivering our services, such as SMS providers. We
                        require these providers to adhere to strict confidentiality obligations.
                    </div>
                    <h5 className="colored-text pb-2">
                        <strong>Data Retention</strong>
                    </h5>
                    <div className="pb-2 ps-4">
                        We retain your phone number and related information for as long as necessary to fulfill the
                        purposes outlined in this policy, or as required by law. Once the purpose is fulfilled, we will
                        securely delete or anonymize your information.
                    </div>
                    <h5 className="colored-text pb-2">
                        <strong>Changes to This Policy</strong>
                    </h5>
                    <div className="pb-2 ps-4">
                        We may update this policy periodically. Please review it regularly for any changes.
                    </div>
                    <h5 className="colored-text pb-2">
                        <strong>Contact Us</strong>
                    </h5>
                    <div className="pb-2 ps-4">
                        For questions or concerns about this policy, please contact us using the information below:
                    </div>
                    <div className="ps-5">
                        1675 Lakes Pkwy Ste 105, Lawrenceville, GA 30043
                    </div>
                    <div className="ps-5">
                        770-344-7300
                    </div>
                    <div className="pb-2 ps-5">
                        <a href="mailto: augh1974@gmail.com">augh1974@gmail.com</a>
                    </div>
                    <div className="pb-2 ps-4">
                        By providing your phone number, you consent to the collection, use, and disclosure of your
                        information as described in this Privacy Policy.
                    </div>
                </div>
            </div>
        </div>
    )
}