import { useEffect } from "react";

export default function PaymentTCDP () {
    useEffect(() =>{
        document.title = "Dual Pricing Cash Discount";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            {/* <br></br>
            <h3 className="colored-text ps-4 ms-3 pb-2">LIQUOR STORE</h3>
            <div className="row col-12 ps-5 ms-4 pb-0">
                <p className="col-md-2 col-sm-5"><a href="#reliance-liquor" className="text-decoration-none colored-text"><strong><u>Reliance Liquor</u></strong></a></p>
                <p className="col-md-2 col-sm-5"><a href="/POS-Korona" className="text-decoration-none text-dark">Korona POS</a></p>
            </div> */}
            {/* <br></br> */}
            <div>
                
                <div className="row col-12 center-header colored-bg header">
                    <div className="header-vertical-align col-xl-7 col-lg-6 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>Dual Pricing Cash Discount</strong></h1>
                                {/* <p className="white-text ms-4 col-10">
                                    Until now, terminal providers could not adapt to the ever changing needs of the payment Industry’s most popular program. But with Valor PayTech, technological advancement has arrived. Make the change today.
                                </p> */}
                                <div className="white-text ms-4 col-10">
                                    <div>
                                        <div className="fw-medium">
                                            Dual Pricing on payment terminals allows merchants to transparently offer different transaction amounts to consumers paying with cash versus cards.
                                        </div>
                                        <ul className="pt-3">
                                            <li>No More Non-Cash Adjustments</li>
                                            <li>Price Transparency at the point of sale</li>
                                            <li>Full Control over Implementation</li>
                                        </ul>
                                        <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                                    </div>
                                </div>

                                {/* <div className="">
                                    <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button">Contact Us</button>
                                </div> */}
                            </div>
                        </span>
                    </div>
                    <div className="col-lg-6 col-xl-5 pe-0">
                        <img src="img/cash-discount.jpg" alt="valor paytech logo" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                    </div>
                </div>
                <div className="light-gray-bg pt-5 pb-5 ps-4 pe-4">
                    <h4 className="ps-md-4 dark-blue-text pb-2">How it Works</h4>
                    <div className="container col-11 mx-auto pt-3">
                        <div className="row mb-2">
                            <div className="col-12">
                                <img src="img/dual-pricing-cash.png" alt="" className="col-12"/>
                            </div>
                            <div className="col-12">
                                <img src="img/dual-pricing-card.png" alt="" className="col-12"/>
                            </div>
                            {/* <div className="col-7 ps-5 pt-lg-5">
                                <div>
                                    <div className="lead dark-blue-text">
                                        <strong>
                                            Dual Pricing on payment terminals allows merchants to transparently offer different transaction amounts to consumers paying with cash versus cards.
                                        </strong>
                                    </div>
                                    <ul className="pt-3 lead">
                                        <li>No More Non-Cash Adjustments</li>
                                        <li>Price Transparency at the point of sale</li>
                                        <li>Full Control over Implementation</li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="mx-auto mb-5 row col-xl-10">
                    <img src="img/zero-fee.jpg" alt="" className="col-5 rounded-borders d-none d-lg-block"/>
                    <div className="col-lg-7 mt-5">
                        <h2 className="col-12 display-4 mx-auto text-center dark-blue-text fw-medium">$0 CREDIT CARD PROCESSING FEE</h2> 
                        <ul className="mx-auto pt-3 ps-md-5 ms-5 me-5 me-lg-0">
                            <li>State-of-the-Art Credit Card Terminal (Free)</li>
                            <li>Complete Installation Service</li>
                            <li>Legally-Compliant Signage</li>
                            <li>Expert Support</li>
                        </ul>
                    </div>
                </div>
                <br></br>
                <br></br>

            </div>

        </div>
    )
}